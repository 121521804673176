<template>
  <div class="screen">
    <!-- <el-card shadow="always" class="screenHead2"> -->
      <!-- <div style="font-size:60px;margin-bottom:10px;">青春中国系列展演活动</div> -->
      <img class="headImg" src="@/assets/11.png" />
      <!-- <div>主办方：江苏省青少年文化交流中心</div> -->
    <!-- </el-card> -->
    <el-card shadow="always">
      <div style="margin-bottom:20px"><span style="font-size:50px">展演顺序</span></div>
      <div class="signShow">
        <el-table stripe :data="list2" style="width: 100%;float:left;clear:both">
          <el-table-column prop="childName" label="考生姓名" align="center"></el-table-column>
          <el-table-column label="展演项目" align="center">
            <template slot-scope="scope">{{scope.row.item2}}</template>
          </el-table-column>
          <el-table-column label="状态" align="center">
            <template slot-scope="scope">
              {{scope.$index == 0 ? "表演中" : scope.$index &lt; 5 ? "准备" : "请选手到后台准备"}}
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>
  </div>
</template>

<script>
import enrollApi from '../api/enroll.js'

export default {
  data() {
    return {
      id:0,
      list1: [{'childName': '张三','team': '小学组A', 'item1': '书画类', 'item2': '油画'},{'childName': '张三','team': '小学组A', 'item1': '书画类', 'item2': '油画'}
        ,{'childName': '张三','team': '小学组A', 'item1': '书画类', 'item2': '油画'},{'childName': '张三','team': '小学组A', 'item1': '书画类', 'item2': '油画'}
        ,{'childName': '张三','team': '小学组A', 'item1': '书画类', 'item2': '油画'},{'childName': '张三','team': '小学组A', 'item1': '书画类', 'item2': '油画'}
        ,{'childName': '张三','team': '小学组A', 'item1': '书画类', 'item2': '油画'},{'childName': '张三','team': '小学组A', 'item1': '书画类', 'item2': '油画'}
        ,{'childName': '张三','team': '小学组A', 'item1': '书画类', 'item2': '油画'},{'childName': '张三','team': '小学组A', 'item1': '书画类', 'item2': '油画'}
      ],
      list2:null,
      page: 1, // 当前页
      limit: 10, // 每页记录数
      total: 0 , // 总记录数
      timer: "",
      child:{}
    }
  },
  methods: {
    getlist(){
      var that = this
      enrollApi.list(this.placeId).then(res => {
        if(res.data.success){
          that.list2 = res.data.data.showList
        }
      })
    },
    handleSizeChange(val) {
      this.limit = val
      this.getlist.call(this)
    },
    handleCurrentChange(val) {
      this.page=val;
      this.getlist.call(this)
    },
    addPage(){
      if(this.page*this.limit>this.total){
        this.page = 1
      }else{
        this.page++
      }
    }
  },
  created() {
    if(this.$route.query.place > 0) {
      this.placeId = this.$route.query.place
      // this.timer = setInterval(this.getlist, 5000)
      this.getlist.call(this)
    }
  },
  beforeDestroy(){
    clearInterval(this.timer)
  }
}
</script>
<style scoped>
.screen{
  margin: 0 auto;
  padding: 10px;
}
.screenHead{
  margin-bottom: 20px;
  background-image: url(../assets/11.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  color:#fff;
}
.headImg{
  width: 100%;
  border-radius: 5px;
  margin-bottom: 5px;
}
.signShow1,.signShow2{
  border-radius:4px;
  border: 1px solid #EBEEF5;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  height: 130px;
}
.signShow2{
  height: calc(100vh - 365px);
}
.signShow{
  clear: both;
  height: calc(100vh - 320px);
}
.block{
  float: left;
  padding-bottom: 20px;
}
.title{
  font-size: 20px;
}
.onlive_txt{
  margin: 10px;
  font-size: 16px;
}
</style>